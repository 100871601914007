import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Offcanvas, Nav, Navbar, Button } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import MenuIcon from "../assets/images/menu-icon.png";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import UserIcon from "../assets/images/user-icon.svg";
import WalletIcon from "../assets/images/WalletIcon.png";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import LogoutIconImg from "../assets/images/logout.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Link } from "react-router-dom";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import NewsIcon from "../assets/images/news-icon.png";
import ApkDownload from "../assets/images/icons/ApkDownload.webp";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import numberWithCommas from "../utils/numbersWithComma";
import { UPI_HUB_GATEWAY_ID } from "../config/const.js";
import ThemeToggle, {
  BUTTON_PARENT_TYPE,
} from "../views/components/ThemeToggle";
import { setSelectedGateway } from "../redux/user/actions";

import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import Slider from "react-slick";

const headerPages = [
  "home",
  "sports",
  "betby",
  "Cricket",
  "sportsbook",
  "live-dealer",
  "vivo",
  "aviator",
  "atlas-lobby",
  "casino",
  "/",
];

const HeaderAfterLogin = () => {
  const { user, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const gateway = {
    id: "XfLFol4F",
    slug: null,
    name: "FAST UPI HUB",
    logo: null,
    type: "auto",
    order: 1,
    code: "gatewayhub",
    value: null,
    displayValue: null,
    qrCode: null,
    url: "/deposit/pay/gatewayhub",
    fields: [
      {
        label: "Amount",
        type: "number",
        accessor: "amount",
        required: true,
        display: true,
        value: null,
      },
    ],
  };
  useEffect(() => {
    const matchedValue = headerPages.find((f) => {
      if (location?.pathname == "/sportsbook") {
        return f === location?.pathname.split("/")?.[1];
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 5000,
  };

  return (
    <>
      {/* {(websiteNotifications.length && showNotifications && (
        <div className="announcement">
          <div className="title">Trending Now</div>
          <Slider {...settings}>
            {websiteNotifications.map((notf, idx) => (
              <div
                className="info"
                key={idx}
                dangerouslySetInnerHTML={{ __html: notf?.content }}
              ></div>
            ))}
          </Slider>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null} */}

      <header className="header afterlogin">
        <div className="topHead">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <Button
                    variant="primary menu-btn"
                    onClick={() => setShowLeftBar((p) => !p)}
                    className="d-block d-md-none"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </Button>

                  <div className="logo">
                    <a href="/sports">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                      {/* <img src={logo} alt="Logo" /> */}
                    </a>
                  </div>

                  <div className="header-left mx-auto d-none d-xl-block">
                    <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                      <Nav className="me-auto">
                        <Nav.Link
                          href="/sports"
                          className={
                            activePage == "sports" || activePage === "/"
                              ? "active"
                              : ""
                          }
                        >
                          Home
                        </Nav.Link>
                        <Nav.Link
                          href="/sportsbook/Cricket"
                          className={activePage == "Cricket" ? "active" : ""}
                        >
                          Exchange
                        </Nav.Link>
                        <Nav.Link
                          href="/betby"
                          className={activePage == "betby" ? "active" : ""}
                        >
                          Sportsbook
                        </Nav.Link>
                        <Nav.Link
                          href="/live-dealer"
                          className={
                            activePage == "live-dealer" ? "active" : ""
                          }
                        >
                          Live Dealers
                        </Nav.Link>
                        <Nav.Link
                          href="/casino/vivo"
                          className={activePage == "vivo" ? "active" : ""}
                        >
                          Vivo
                        </Nav.Link>
                        <Nav.Link
                          href="/casino/spribe/aviator"
                          className={activePage == "aviator" ? "active" : ""}
                        >
                          Aviator
                        </Nav.Link>
                        <Nav.Link
                          href="/atlas-lobby"
                          className={
                            activePage == "atlas-lobby" ? "active" : ""
                          }
                        >
                          Sportsbook2
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </div>

                  <div className="headerRight ms-auto ms-xl-0">
                    <a
                      href="https://dl.dropbox.com/scl/fi/ijir5iy862ma8s3x4079y/Goa777.apk?rlkey=5baa2yvdgdqynjgujrdu9sgnb&st=9j4jtav2&dl=0"
                      download="https://dl.dropbox.com/scl/fi/ijir5iy862ma8s3x4079y/Goa777.apk?rlkey=5baa2yvdgdqynjgujrdu9sgnb&st=9j4jtav2&dl=0"
                      className="me-2 ApkDownload d-md-none d-flex"
                    >
                      <img src={ApkDownload} alt="apk download" width={40} />
                    </a>
                    <ul>
                      <li className="mx-2">
                        <GamesSearchModal />
                      </li>
                      <li className="balance">
                        <div className="balance-amount" href="/cashier">
                          {/* <img src={BalanceIcon} alt="Balance Icon" width={24} /> */}
                          <a href="/withdraw" className="WithDrawHeader">
                            W
                          </a>
                          <div className="d-sm-flex align-items-sm-center">
                            <div className="balance d-flex align-items-center">
                              <span>Bal:&nbsp;</span>
                              <span>{wallet?.balance}</span>
                            </div>
                            <div className="bonus">
                              Cash: {numberWithCommas(wallet?.cash)}
                            </div>
                            <div className="bonus">
                              Bonus: {numberWithCommas(wallet?.coins)}
                            </div>
                          </div>
                          <a
                            onClick={() => {
                              // dispatch(setSelectedGateway(gateway));
                              navigate(`/deposit/${UPI_HUB_GATEWAY_ID}`);
                            }}
                            className="WithDrawHeader"
                          >
                            D
                          </a>
                        </div>
                      </li>

                      <li className="head-social">
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIcon} alt="telegram" />
                        </a>
                      </li>
                    </ul>

                    <Button
                      variant="primary menu-btn"
                      onClick={() => setShow(true)}
                      className="d-none d-md-block"
                    >
                      <img src={MenuIcon} alt="User Menu Icon" width={25} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas
          className="user_section"
          show={show}
          onHide={() => setShow(false)}
          placement={"end"}
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={UserIcon} alt="user" />
                </div>
                <div className="user-info">
                  <Link className="user_name" to="/profile">
                    {user?.mstruserid}
                  </Link>
                  <p>{user?.mobileno}</p>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="container upperbalnace">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <img src={WalletIcon} alt="wallet" />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    INR {wallet?.balance}
                  </div>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate(`/deposit/${UPI_HUB_GATEWAY_ID}`);
                    }}
                    className="btn_primary w-100"
                  >
                    Deposit
                  </Button>
                </div>
              </div>
            </div>

            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={UserIcon} alt="user" />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={BetIcon} alt="my bet" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus" className="img_size">
                  <img src={BonusImg} />
                  <span className="bonus-space">Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral" className="img_size">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={AcStatement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>
              <li onClick={() => dispatch(logoutUser(true))}>
                <a href="#">
                  <img src={LogoutIconImg} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ThemeToggle buttonParentType={BUTTON_PARENT_TYPE} />
          </Offcanvas.Body>
        </Offcanvas>

        {showLeftBar && <MobLeftbar className="d-block d-md-none" />}
      </header>
    </>
  );
};

export default HeaderAfterLogin;
